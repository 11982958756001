var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"commonDialog":_vm.dialog,"dialogWidth":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-layout',[_c('v-flex',[_vm._v(" Manage Team Member")])],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"teamForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateorcreateteams()}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"max-height":"90vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('table',{staticClass:"width-100"},[_c('tbody',[_c('tr',[_c('td',{attrs:{"width":"50%"}},[_c('label',{staticClass:"font-weight-700 font-size-16 required"},[_vm._v(" Enter Team Name")]),_c('v-text-field',{staticClass:"width-100",attrs:{"dense":"","filled":"","label":"Enter Team Name","solo":"","flat":"","hide-details":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan","rules":[
                          _vm.validateRules.required(
                            _vm.teamManage.team_name,
                            'Enter Team Name'
                          ),
                        ]},model:{value:(_vm.teamManage.team_name),callback:function ($$v) {_vm.$set(_vm.teamManage, "team_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"teamManage.team_name"}})],1),_c('td',{attrs:{"width":"50%"}},[[_c('label',{staticClass:"font-weight-700 font-size-16 required"},[_vm._v(" Select Technicians")]),_c('v-autocomplete',{staticClass:"width-100",attrs:{"dense":"","color":"cyan","filled":"","id":"job-type","items":_vm.engineerList,"disabled":_vm.pageLoading,"loading":_vm.pageLoading,"label":"Select Technicians","solo":"","flat":"","item-color":"cyan","rules":[
                            _vm.validateRules.required(
                              _vm.teamManage.staffData,
                              'Select Technicians'
                            ),
                          ],"placeholder":"Select Technicians","item-text":"display_name","item-value":(row) => row.id,"hide-details":"","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Technicians Found.')}})],1)],1)]},proxy:true},{key:"value",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Technicians Found.')}})],1)],1)]},proxy:true}]),model:{value:(_vm.teamManage.staffData),callback:function ($$v) {_vm.$set(_vm.teamManage, "staffData", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"teamManage.staffData"}})]],2)])])])])],1)],1)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button white--text",attrs:{"color":"cyan","disabled":!_vm.formValid},on:{"click":_vm.updateorcreateteams}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.pageLoading},on:{"click":_vm.CloseLeave}},[_vm._v(" Close ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }